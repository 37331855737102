.stack-jznghy {
    display: flex;
}

.horizontal-64bzg {
    flex-direction: row;
}

.vertical-64bzg {
    flex-direction: column;
}

.fill-OytHg {
    width: 100%;
    height: 100%;
}

.gap-5-Knzh { gap: 5px; }
.gap-10-hNz3 { gap: 10px; }
.gap-20-74Hd { gap: 20px; }
.gap-40-AgTd { gap: 40px; }