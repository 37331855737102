.category-item-lsna {
  display: flex;
  align-items: center;
  padding: 10px;
}

.category-item-lsna:hover {
  background-color: lightgoldenrodyellow;
}

.button-open-5hA {
  color: var(--color-accent-red);
  cursor: pointer;
  user-select: none;
  font-size: 24px;
  line-height: 16px;
}

.category-title-sjUY {
  cursor: pointer;
  transition: opacity .1s;
}

.category-title-sjUY:hover {
  opacity: .8;
}