.logo-Ajs {
  font-weight: 900;
  font-size: 26px;
  line-height: 38px;
}

.logo-text-AAys {
  color: var(--color-accent-red);

}

.logo-logo-NZhd {
  color: var(--color-background);
  background-color: var(--color-accent-red);
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 20px;
}