.input-file-Ndhsy::file-selector-button {
  margin-right: 20px;
  border: none;
  background: var(--color-accent-red);
  color: #fff;
  cursor: pointer;
  transition: opacity .1s;
}

.input-file-Ndhsy::file-selector-button:hover {
  opacity: .8;
}

.image-JShy {
  height: 100%;
  width: 200px;
}

.title-NSAjd {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}