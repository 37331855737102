.review-item-lahuae {
  background-color: #efefef;
  padding: 10px;
}

.review-item-rating-caljbh {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.review-item-buttons-caljbh {
  display: flex;
  align-items: center;
}

.review-item-buttons-caljbh button {
  width: 100px;
  text-align: center;
  line-height: 30px;

  border: none;

  background-color: var(--color-accent-yellow);

  text-align: center;
  transition: opacity 0.1s;
  cursor: pointer;
}

.review-item-buttons-caljbh button:hover {
  opacity: 0.8;
}

.review-item-buttons-caljbh button:active {
  opacity: 0.5;
}

.review-date-alksbb {
  font-size: 12px;
  font-style: italic;
}
