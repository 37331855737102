.product-card-74js {
  background-color: #efefef;
  padding: 10px;
}

.image-block-Jdy3 {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: gray;
  text-align: center;
}

.product-info-U3js {
  font-size: 12px;
}

.product-title-Jsy1 {
  cursor: pointer;
}

.product-title-Jsy1:hover {
  opacity: 0.8;
}

.delete-button-Jeys {
  height: 100%;
  width: 30px;
}
