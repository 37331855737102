.import-files-list-slkj {
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  padding: 20px;
  background-color: lightgoldenrodyellow;
}

.no-import-files-lskaf {
  text-align: left;
}

.title-ksajjdv {
  text-align: left;
  font-weight: 600;
  align-items: center;
}

.button-add-import-file-smiaokn {
  text-align: center;
  width: 25px;
  line-height: 25px;

  font-weight: normal;
  font-size: 25px;

  border: none;

  background-color: var(--color-accent-yellow);

  text-align: center;
  transition: opacity 0.1s;
  cursor: pointer;
}

.button-add-import-file-smiaokn:hover {
  opacity: 0.8;
}

.button-add-import-file-smiaokn:active {
  opacity: 0.5;
}

.input-import-file-aljvh {
  display: none;
}
