.loader-box-47Hsy {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-products-J74h {
  text-align: center;
  font-style: italic;
}