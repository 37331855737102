.scroll-Nxbg1 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.scroll-Nxbg1::-webkit-scrollbar {
  display: none;
}

.content-4Hdba {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}