.button-NZhf {
    background-color: var(--color-accent-red);
    color: var(--color-background);
    min-height: 35px;
    min-width: 30px;
    text-align: center;
    opacity: 0.9;
    transition: opacity 0.1s;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;

    transition: background-color .5s, color .5s;
}

.button-NZhf:hover {
    opacity: 1;
}

.button-NZhf:active {
    opacity: 0.8;
}

.trans-Mzn {
    color: var(--color-accent-red) !important;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.1s;
}

.trans-Mzn:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.trans-Mzn:active {
    background-color: rgba(0, 0, 0, 0.3);
}

.disabled-UqH {
    opacity: 0.3 !important;
    cursor: default;
}