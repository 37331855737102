.sidebar-JSy3dy {
  background-color: #292929;
  padding: 20px;
  width: 200px;
  height: 100%;
}

.tab-Nsh3 {
  color: var(--color-background);
  font-size: 16px;
}

.selected-4ys {
  color: var(--color-accent-yellow); 
}

.disabled-hf5 {
  opacity: .3;
}

.enabled-4Tad {
  cursor: pointer;
  user-select: none;
}

.enabled-4Tad:hover {
  opacity: .7;
}