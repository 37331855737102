.header-Hdydh {
  padding-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid var(--color-accent-red);
}

.footer-Jdhsy {
  padding-top: 10px;
  border-top: 1px solid var(--color-accent-red);
}

.close-button-Hfy {
  color: var(--color-accent-red);
  cursor: pointer;
}

.close-button-Hfy:hover {
  opacity: .5;
}

.description-JSu2 {
  min-height: 150px;
  background: white;
  width: calc(100% - 20px);
  padding: 10px;
  border-color: var(--color-accent-red);
}

.description-JSu2:focus {
  outline: none;
}

.load-images-button-U3gah {
  font-size: 14px;
}

.loader-container-Jdys {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.modal-title-sjdy {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.product-image-Jwuya {
  max-width: 230px;
  max-height: 250px;
}

.product-image-container-Jdna {
  position: relative;
}

.delete-image-U3jd {
  position: absolute;
}

.images-block-64Hs {
  align-items: center;
}