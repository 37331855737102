.account-modal-container-aljchq {
  width: 300px;
  height: auto;
}

.header-ajcakoaln {
  padding-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid var(--color-accent-red);
}

.footer-paonovks {
  padding-top: 10px;
  border-top: 1px solid var(--color-accent-red);
}

.close-button-LIanlaq {
  color: var(--color-accent-red);
  cursor: pointer;
}

.close-button-LIanlaq:hover {
  opacity: 0.5;
}

.loader-container-OAmoaq {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.modal-title-aondaeq {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.account-is-admin-alkjbqa {
  align-items: center;
}
