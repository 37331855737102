:root {
  --color-background: #F8F7F3;
  --color-accent-red: #C12E42;
  --color-accent-yellow: #FFD30C;
}

html body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-background);

  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}