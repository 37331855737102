.logo-ncl1 {
  justify-content: center;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  user-select: none;
}

.logo-text-ncl1 {
  color: var(--color-accent-red);
}

.logo-logo-ncl1 {
  color: var(--color-accent-red);
  background-color: #F8F7F3;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 20px;
}