.textbox-ZMjl5 {
  width: calc(100% - 22px);
  height: 35px;
  background-color: var(--color-background);
  outline: none;
  border: 1px solid var(--color-accent-red);
  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;
  font-weight: 400;
  padding: 0 10px;
  transition: border-color 0.2s;

  transition: background-color 0.5s, color 0.5s;
}

.textbox-ZMjl5::placeholder {
  color: var(--color-accent-red);
  opacity: 0.6;
}

.textbox-ZMjl5:focus {
  border-color: black;
}

.textbox-ZMjl5:focus::placeholder {
  opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.error-MZdjY {
  background-color: color-mix(in srgb, var(--color-accent-red) 20%, var(--color-background));
}
