.import-file-item-askd {
  align-items: center;
  background-color: #efefef;
  padding: 10px;
}

.import-file-item-button-askd {
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;

  text-decoration: none;

  border: none;

  background-color: var(--color-accent-yellow);
  color: black;

  text-align: center;
  transition: opacity 0.1s;
  cursor: pointer;
}

.import-file-item-button-askd:hover {
  opacity: 0.8;
}

.import-file-item-button-askd:active {
  opacity: 0.5;
}

.import-file-item-a-askd {
  display: none;
}
