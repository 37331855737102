.header-busoafs {
  padding-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid var(--color-accent-red);
}

.order-content-alhq {
  font-size: 16px;
}

.footer-qlaqblq {
  padding-top: 10px;
  border-top: 1px solid var(--color-accent-red);
}

.close-button-oiabvja {
  color: var(--color-accent-red);
}

.close-button-oiabvja:hover {
  opacity: 0.5;
}

.loader-container-alksjc {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.modal-title-slkdjq {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
