.categories-list-Nz2 {
  width: 300px;
  height: 100%;
  background-color: lightgoldenrodyellow;
}

.title-Hf63 {
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
}

.no-categories-Hy3 {
  font-size: 14px;
  text-align: center;
}
