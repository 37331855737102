.input-file-akjljhajhs::file-selector-button {
  margin-right: 20px;
  border: none;
  background: var(--color-accent-red);
  color: #fff;
  cursor: pointer;
  transition: opacity 0.1s;
}

.input-file-akjljhajhs::file-selector-button:hover {
  opacity: 0.8;
}

.title-NSAakjscu {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.close-button-auscijlan {
  color: var(--color-accent-red);
  cursor: pointer;
}

.close-button-auscijlan:hover {
  opacity: 0.5;
}
