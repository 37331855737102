.product-card-akbjdc {
  background-color: #efefef;
  padding: 10px;
}

.image-block-aonakcjb {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: gray;
  text-align: center;
}

.product-count-alkbc {
  text-align: center;
}
