.header-busoafs {
  padding-bottom: 10px;
  font-size: 20px;
  border-bottom: 1px solid var(--color-accent-red);
}

.footer-ISsons {
  padding-top: 10px;
  border-top: 1px solid var(--color-accent-red);
}

.close-button-AUyiai {
  color: var(--color-accent-red);
  cursor: pointer;
}

.close-button-AUyiai:hover {
  opacity: 0.5;
}

.description-AOdcoabi {
  min-height: 150px;
  background: white;
  width: calc(100% - 20px);
  padding: 10px;
  border-color: var(--color-accent-red);
}

.description-AOdcoabi:focus {
  outline: none;
}

.loader-container-LKNcds {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.modal-title-laajv {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.review-rating-alkcb {
  align-items: center;
}

.select-wrapper-alkbj {
  overflow: hidden;
  display: inline-block;
  border: 1px solid var(--color-accent-red);
  font-family: 'Montserrat', 'sans-serif';
  background: transparent;
  padding: 0;
  height: 50px;
  min-width: 200px;
  max-width: 300px;
}

.select-alaokn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  font-weight: 400;
  font-size: 16px;
  border-radius: 0;
  padding: 0 0 0 1.25em;
  width: 100%;
  height: 100%;
  border: none;
}

.select-option-alkba {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: whitesmoke;
  display: block;
  width: 20px;
  height: 40px;
  border: 2px solid black;
}

.select-icon-aljcnav {
  position: relative;
  bottom: 51px;
  float: right;
  padding: 16px;
  height: 100%;
  border: 1px solid #c8c8c8;
  border-right: none;
  pointer-events: none;
}

.review-date-aljha {
  width: 260px;
}
