.category-selected-ljnav {
  background-color: #efefef;
  padding: 20px;
}

.category-img-Jdys {
  width: 110px;
  height: 110px;
  line-height: 110px;
  font-size: 16px;
  text-align: center;
  background-color: lightgray;
}

.button-aadj {
  width: 200px;
  text-align: center;
  line-height: 30px;

  border: none;

  background-color: var(--color-accent-yellow);

  text-align: center;
  transition: opacity 0.1s;
  cursor: pointer;
}

.button-aadj:hover {
  opacity: 0.8;
}

.button-aadj:active {
  opacity: 0.5;
}
